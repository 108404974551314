import React, { useState } from "react";
import qs from 'qs';
import { FormattedMessage } from "react-intl";
import { Configure, InstantSearch, MenuSelect } from 'react-instantsearch-dom';
import IconProfileSetup from "../IconProfileSetup/IconProfileSetup";
import { AlgoliaAutoCompleteSearchField, Button } from "..";
import CategoryDropdown from "./CategoryDropdown";
import algoliasearch from "algoliasearch";
import { orderBy } from 'lodash';
import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const updateAfter = 700;
const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    // 'latency',
    // '6be0576ff61c053d5f9a3225e2a90f76',
    {
        _useRequestCache: true,
    }
);

const AlgoliaSearchComponent = props => {
    const {
        intl,
        onFetchCategories,
        categories,
        subCategories,
        history
    } = props;

    const parameters = {
        filters: 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")',
    };

    const [dropDownOpen, setdropDownOpen] = useState(false);

    const [searchState, setSearchState] = useState(
        typeof window !== 'undefined' && qs.parse(window.location.search.slice(1))
    );

    const handleSearchChange = value => setSearchState(Object.assign(searchState, value));
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 920;

    const onSearchStateChange = params => {
        const { menu = {} } = params;
        setTimeout(() => {
            const urlPath =
                `/search/${menu?.region}/all/all/all` +
                `${searchState.query ? `?keywords=${searchState.query}` : ''}`;
            history.push(urlPath);
        }, updateAfter);
    };

    return (
        <div className={css.heroContent}>
            <div className={css.mainCover}>
                <h1>
                    <FormattedMessage id="SectionHero.headingTitle" />
                </h1>
                <div className={css.searchBar}>
                    <div className={css.searchLeft}>
                        <div className={css.locationDropDown}>
                            <InstantSearch
                                searchClient={searchClient}
                                indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX}
                                searchState={searchState}
                                onSearchStateChange={onSearchStateChange}
                            >
                                <Configure {...parameters} />
                                <MenuSelect
                                    limit={30}
                                    attribute={'region'}
                                    className={css.searchForm}
                                    transformItems={items => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                                    translations={{
                                        seeAllOption: intl.formatMessage({ id: 'AlgoliaSearchPage.byRegion' }),
                                    }}
                                />
                            </InstantSearch>
                        </div>

                        <div className={css.searchWrapper}>
                            <div className={css.keyWordBox} onClick={() => setdropDownOpen(!dropDownOpen)}>
                                <span className={css.searchIcon}>
                                    <IconProfileSetup type="search" />
                                </span>
                                <AlgoliaAutoCompleteSearchField
                                    className={css.searchKeyword}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            {dropDownOpen ? (
                                <CategoryDropdown
                                    isMobile={isMobile}
                                    categories={categories}
                                    intl={intl}
                                    subCategories={subCategories}
                                    dropDownOpen={dropDownOpen}
                                    setdropDownOpen={setdropDownOpen}
                                    onFetchCurrentCategories={payload => onFetchCategories(payload).then(() => setdropDownOpen(true))}
                                />
                            ) : null}
                        </div>
                    </div>

                    <Button
                        className={isMobile ? css.goFullButton : css.goButton}
                        type="button"
                        onClick={() => {
                            const urlSearch =
                                'search/all/all/all/all' +
                                `${searchState.query ? `?keywords=${searchState.query}` : ''}`;
                            history.push(urlSearch);
                        }}
                    >
                        <FormattedMessage
                            id={isMobile ? 'SectionHero.findVendersText' : 'SectionHero.goText'}
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default AlgoliaSearchComponent;