import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { NamedLink, IconCollection, OutsideClickHandler } from '..';

import css from './SectionHero.module.css';

const CategoryDropdown = props => {
    const {
        isMobile,
        categories,
        subCategories,
        dropDownOpen,
        intl,
        setdropDownOpen,
        onFetchCurrentCategories,
    } = props;

    const [category, setCategory] = useState("Services");

    const mainCategoriesLabel = intl.formatMessage({ id: "CategoryDropdown.mainCategoryLabel" });
    const subCategoriesLabel = intl.formatMessage({ id: "CategoryDropdown.SubCategories" });

    return (
        <OutsideClickHandler onOutsideClick={() => dropDownOpen && setdropDownOpen(false)}>
            <div className={css.categoryDropDown}>
                <div className={css.categoriesTitle}>
                    <p>{mainCategoriesLabel}</p>
                    {isMobile ? null : <p>{subCategoriesLabel}</p>}
                </div>
                <div className={classNames(css.categoriesWrapper, isMobile && css.mobileWrapper)}>
                    <div className={css.mainCategories}>
                        <ul>
                            {categories && categories.length
                                ? categories.map((c, i) => (
                                    <React.Fragment key={c.label + "_" + i}>
                                        <li
                                            className={classNames(css.mainCategoriesItem, category === c.label && css.activeCategory)}
                                            onClick={() => {
                                                onFetchCurrentCategories({
                                                    type: "subCategories",
                                                    parentIds: [c.id],
                                                    clearPrev: true
                                                });
                                                setCategory(c.label);
                                            }}
                                        >
                                            <span style={{ display: "flex" }}>
                                                <IconCollection name="ICON_CATEGORY" />
                                                &nbsp;
                                                <span>{c.label}</span>
                                            </span>
                                            <span>
                                                <IconCollection name="ICON_NEXT" />
                                            </span>
                                        </li>

                                        {isMobile && category === c.label
                                            ? (<div className={css.mobileSubcategories}>
                                                <p><b>{subCategoriesLabel}</b></p>
                                                <ul className={css.categoriesItem}>
                                                    {subCategories && subCategories.length
                                                        ? subCategories.map((sc, j) => (<li key={sc.label + "_" + j}>
                                                            <NamedLink
                                                                name="AlgoliaSearchPage"
                                                                key={sc.key}
                                                                className={css.heroCategories}
                                                                to={{
                                                                    categoriesLabel: category,
                                                                    subCategoriesLabel: sc.key
                                                                }}
                                                            >
                                                                {sc.label}
                                                            </NamedLink>
                                                        </li>))
                                                        : null}
                                                </ul>
                                            </div>)
                                            : null}
                                    </React.Fragment>
                                ))
                                : null}
                        </ul>
                    </div>

                    {isMobile
                        ? null
                        : (<div className={css.Subcategories}>
                            <ul className={css.categoriesItem}>
                                {subCategories && subCategories.length
                                    ? subCategories.map((sc, i) => (
                                        <li key={sc.label + "_" + i}>
                                            <NamedLink
                                                name="AlgoliaSearchPage"
                                                key={sc.key}
                                                className={css.heroCategories}
                                                to={{
                                                    categoriesLabel: category,
                                                    subCategoriesLabel: sc.key
                                                }}
                                            >
                                                {sc.label}
                                            </NamedLink>
                                        </li>
                                    ))
                                    : null}
                            </ul>
                        </div>)}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default CategoryDropdown;